import React from 'react';
import HeaderOne from "./header/HeaderOne";
import HeaderTopNews from "./header/HeaderTopNews";
import FooterTwo from "./footer/FooterTwo";
import Copyright from "./footer/Copyright";
import CustomHeaderOne from './header/CustomHeaderOne';
import CustomFooterFour from './footer/CustomFooter';

const CustomLayout = ({children}) => {
    return (
        <>
            <main className="page-wrapper">
                <CustomHeaderOne btnStyle="btn-small btn-icon round" HeaderSTyle="header-not-transparent" />

                
                {children}

                

                <CustomFooterFour/>
            </main>
        </>
    )
}
export default CustomLayout;
