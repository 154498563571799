import React from 'react';
import {Link} from "react-router-dom";
import ScrollTop from "./ScrollTop";
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin, FiYoutube } from "react-icons/fi";

const CustomFooterFour = () => {
    return (
        <>
            <footer className="rn-footer footer-style-default no-border ">
               
                {/* <NewsletterOne newsletetrStyle="rn-newsletter-default" extraClass="border-top-bottom" /> */}
                <div className="copyright-area copyright-style-one no-border">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="copyright-left">
                                    <ul className="ft-menu link-hover">
                                        <li><a href="privacy">Privacy Policy</a></li>
                                        <li><a href="terms">Terms And Condition</a></li>

                                        <ul className="social-icon social-default justify-content-start">
                                            <li><Link to="#"><FiFacebook /></Link></li>
                                            <li><Link to="#"><FiTwitter /></Link></li>
                                            <li><Link to="#"><FiInstagram /></Link></li>
                                            <li><Link to="#"><FiLinkedin /></Link></li>
                                            <li><a target='_blank' href="https://www.youtube.com/@switchonstudio"><FiYoutube /></a></li>
                                        </ul>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="copyright-right text-center text-md-right">
                                    <p className="copyright-text">© Switch On Studio {new Date().getFullYear()}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </footer>
            <ScrollTop />
        </>
    )
}

export default CustomFooterFour;
