import React from 'react';
import Slider from "react-slick";
import SEO from "../common/SEO";
import { BannerActivation } from "../utils/script";
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import AboutOne_TugOfWar from '../elements/about/AboutOne_TugOfWar';
import TestimonialOne_TugOfWar from "../elements/testimonial/TestimonialOne_TugOfWar";
import CustomHeaderOne from '../common/header/CustomHeaderOne';
import CustomFooterFour from '../common/footer/CustomFooter';

const BannerData = [
    {
        image: "/images/bg/bg-image-18_1.jpg",
        title: "A Creative <br /> Game Development Company.",
        description: "We like to develop games which are fun to play."
    },    
]

const CustomHome = () => {
    return (
        <>
            <SEO title="Creative Innovations" />
            <main className="page-wrapper">
                <CustomHeaderOne btnStyle="btn-small btn-icon round" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <Slider className="slider-area slider-style-4 variation-2 slider-dot rn-slick-dot rn-slick-arrow" {...BannerActivation}>
                    {BannerData.map((data, index) => (
                        <div key={index} className="single-slide">
                            <div className="height-950 bg-overlay bg_image" style={{backgroundImage: `url(${process.env.PUBLIC_URL} ${data.image})`}}>
                                <div className="container">
                                    <div className="row row--30 align-items-center">
                                        <div className="col-lg-12">
                                            <div className="inner text-center">
                                                <h1 className="title" dangerouslySetInnerHTML={{__html: data.title}}></h1>
                                                <p className="description" dangerouslySetInnerHTML={{__html: data.description}}></p>
                                                <div className="button-group mt--30">
                                                    <a className="btn-default btn-large round" target="_blank" href="https://play.google.com/store/apps/dev?id=5454228516552923967">Visit Playstore</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
                {/* End Slider Area  */}

                <div className="rn-section-gapTop"/>
                
                <SectionTitle
                    textAlign = "text-center"
                    radiusRounded = ""
                    subtitle = "Portfolio"
                    title = "Games."
                />

                <AboutOne_TugOfWar image="./images/about/contact-image.jpg" />            

                <Separator />                         
                
                {/* Start Elements Area  */}
                <div className="rwt-testimonial-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb--10">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Player's Feedback"
                                    title = "Testimonial."
                                />
                            </div>
                        </div>
                        <TestimonialOne_TugOfWar column="col-lg-4 col-md-6 col-12 mt--30" teamStyle="card-style-default testimonial-style-one" />
                    </div>
                </div>
                {/* End Elements Area  */}
                <Separator />
            
                <CustomFooterFour />
            </main>
        </>
    )
}

export default CustomHome;
