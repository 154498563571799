import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop'

// Pages import Here 
import CustomHome from "./pages/CustomHome";
import CustomPageNotFound from './pages/CustomPageNotFound';


// Import Css Here 
import './assets/scss/style.scss';
import WebsitePrivacyPolicy from './pages/WebsitePrivacy';
import WebsiteTerms from './pages/WebsiteTerms';

const CustomApp = () => {
    return (
        <Router>
            <PageScrollTop>
                <Switch>
                <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={CustomHome}/>                
                <Route path={`${process.env.PUBLIC_URL + "/privacy"}`} exact component={WebsitePrivacyPolicy}/>
                <Route path={`${process.env.PUBLIC_URL + "/terms"}`} exact component={WebsiteTerms}/>

                {/*setup page not found*/}
                <Route path="*" exact component={CustomPageNotFound} />
                </Switch>
            </PageScrollTop>
        </Router>
    )
}


export default CustomApp
