import React from 'react';
import Typed from 'react-typed';
import { FiCheck, FiStar } from "react-icons/fi";

const AboutOne_TugOfWar = () => {
    return (
        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/about-tugOfWar.png" alt="About Images" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title">Tug Of War <br /> {" "}
                                    <Typed className="theme-gradient"
                                        strings={[
                                            "Fun.",
                                            "Addictive.",
                                            "Friendly.",
                                        ]}
                                        typeSpeed={80}
                                        backSpeed={5}
                                        backDelay={1000}
                                        loop
                                    />
                                </h2>
{/* 
                                <div className="rating">
                                    <a style={{
                                        
                                        color: "#f1b304",
                                        svg: {
                                            fill: "#f1b304"
                                        }
                                    }}><FiStar /></a>
                                    <a><FiStar /></a>
                                    <a><FiStar /></a>
                                    <a><FiStar /></a>
                                    <a><FiStar /></a>
                                </div> */}
                                
                                <p>Fun for Families! Easy Tug-of-War Game with Friends & AI</p>
                                <p>Pull, laugh, and compete in Tug Of War, the free-to-play game that's perfect for families, casual gamers, and kids!</p>
                                <p>Challenge your friends and family to a classic tug-of-war battle, or test your skills against clever AI opponents. It's simple to learn, addictive to play, and offers endless fun for everyone!</p>                                                                
                                
                                <div className="read-more-btn mt--40">
                                    <a className="btn-default" target="_blank" href="https://play.google.com/store/apps/details?id=com.SwitchOnStudio.TugOfWar"><span>Download</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutOne_TugOfWar
